<template>
  <div class="user">
    <Search :handleInput="handleInput" />
    <Table
      :loading="loading"
      :rowHeader="rowHeader"
      :data="data"
      :pageObj="pageObj"
      :pageShow="pageShow"
      :handleSizeChange="handleSizeChange"
      :handleCurrentChange="handleCurrentChange"
    />
    <el-dialog
      :title="`${dialogType === 1 ? '编辑' : '删除'}设备`"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible">
        <EditUser
          v-if="dialogType === 1"
          :handleClose="handleClose"
          :sidebarData="sidebarData"
          :getUserUpdate="getUserUpdate"
          :paramsObj="paramsObj"
        />
        <DeleteAny
          v-else
          :handleClose="handleClose"
          :name="paramsObj.name"
          :typeName="typeName"
          :getDelFunc="getUserDelete"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "User",
  components: {
    Table: () => import("@/components/Table"),
    Search: () => import("./components/Search"),
    EditUser: () => import("./components/EditUser"),
    DeleteAny: () => import("@/components/DeleteAny"),
  },
  data() {
    return {
      pageShow: true,
      loading: false,
      pageObj: {
        current: 1,
        total: 0,
      },
      page: 1,
      size: 10,
      rowHeader: [
        {
          label: "序号",
          width: "80",
          render: (h, params) => {
            return (
              <span>
                {params.index + (this.pageObj.current - 1) * this.pageObj.size}
              </span>
            );
          },
        },
        {
          prop: "username",
          label: "用户账号",
        },
        {
          prop: "name",
          label: "用户名",
        },
        {
          prop: "deptName",
          label: "所属园区/地块",
        },
        {
          prop: "modifier",
          label: "角色",
        },
        {
          prop: "phone",
          label: "联系电话",
        },
        {
          prop: "btn",
          label: "操作",
          render: (h, params) => {
            return (
              <div class="btn_box">
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(1, params.row)}
                >
                  编辑
                </span>
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(2, params.row)}
                >
                  删除
                </span>
              </div>
            );
          },
        },
      ],
      data: [],
      username: "",
      dialogType: 1,
      dialogVisible: false,
      paramsObj: {},
      sidebarData: [],
      typeName: "用户",
    };
  },
  created() {
    this.$emit("tabIndex", "2-1");
    this.getDeptTree();
    this.getUserPage();
  },
  methods: {
    handleInput(value) {
      this.username = value;
      this.page = 1;
      this.size = 10;
      this.getUserPage();
    },
    async getUserPage() {
      this.loading = true;
      const { data } = await this.$api.getUserPage({
        page: this.page,
        size: this.size,
        username: this.username,
        deptId: JSON.parse(window.sessionStorage.getItem("userInfo")).deptId,
      });
      this.data = data.records;
      this.pageObj = data;
      this.loading = false;
    },
    // 查询园区地块
    async getDeptTree() {
      const { data } = await this.$api.getDeptTree({ id: this.deptId });
      this.sidebarData = data;
    },
    // 更新用户
    async getUserUpdate(userObj) {
      console.log("userObj", userObj);
      const res = await this.$api.getUserUpdate(userObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getUserPage();
      this.handleClose();
    },
    // 删除用户
    async getUserDelete() {
      let ids = JSON.stringify([this.paramsObj.id]);
      const res = await this.$api.getUserDelete({ reIds: ids });
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getUserPage();
      this.handleClose();
    },
    // 条数
    handleSizeChange(val) {
      this.size = val;
      this.getUserPage();
    },
    // 页数
    handleCurrentChange(val) {
      this.page = val;
      this.getUserPage();
    },
    handleOpenDialog(id, row) {
      this.paramsObj = row;
      this.dialogType = id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  display: inline-block;
  width: calc(100% - 40px);
  min-height: calc(100vh - 110px);
  margin: 20px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  &-title {
    font-size: 18px;
    text-align: left;
  }
}
</style>
